  import image1 from "../assets/image1.webp";
  import oliguncleotideImage from "../assets/OluPeptide.webp";
  import oliguncleotideImageFull from "../assets/OligonucleaotideSyntesizers.webp"
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
  import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
  import homeBanner from "../assets/homebanner.webp";
  import { Link } from "react-router-dom";

  interface HeroCarouselProps {
    scrollToProducts: () => void;
  }


  export const HeroCarousel = ({ scrollToProducts }: HeroCarouselProps) => {
    const NextArrow = (props: any) => {
      const {  onClick } = props;
      return (
        <div
          className={`slick-arrow`}
          onClick={onClick}
          style={{ right: "10px" }}
        >
          <FontAwesomeIcon
            icon={faArrowRight}
            className="text-white border border-white p-2 text-lg  md:text-3xl absolute md:end-10 end-5 top-[50%] -translate-y-[50%]"
          />
        </div>
      );
    };

    function PrevArrow(props: any) {
      const { onClick} = props;
      return (
        <div
          className={`slick-arrow`}
          onClick={onClick}
          style={{ right: "10px" }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-white border border-white p-2 text-lg md:text-3xl absolute md:start-10 start-5 top-[50%] -translate-y-[50%] z-10"
          />
        </div>
      );
    }

    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
      <div className="">
        <Slider {...settings}>
           <div className="relative">
            <div className="hidden min-[906px]:block w-full">
              <img src={image1} alt="Home banner" />
            </div>
            <div className="block min-[906px]:hidden w-full">
              <img src={homeBanner} alt="Home Banner" className="h-[50vh] w-full object-cover" />
            </div>

            <div className="absolute z-50  gap-7 flex-col w-[50%] flex
            top-[50%] left-[4%] -translate-y-1/2 max-[905px]:left-[50%] max-[905px]:w-[80%] max-[415px]:w-[95%] max-[905px]:-translate-x-1/2
            ">
              <p className="text-[32px] max-[1080px]:text-[30px] max-[768px]:text-[25px] max-[768px]:font-medium max-[768px]:leading-8 max-[980px]:text-[25px] font-bold text-center text-white  leading-10">
                The World's Premier Manufacturer <br /> of Efficient, Innovative,
                <br />
                Patented, Automated Multiple <br /> Peptide Synthesizers.
              </p>
              <div className="flex gap-3 justify-center">
                <button
                  onClick={scrollToProducts}
                  className="bg-white max-[447px]:text-sm font-semibold max-w-fit  transition-colors text-primary px-4 py-2 text-base "
                >
                  Explore Products
                </button>
                <Link to={"/request-quote"} >
                  <button className="bg-white max-[447px]:text-sm font-semibold  transition-colors text-primary px-4 py-2 text-base  ">
                    Request a Quote
                  </button>
                </Link>
              </div>
            </div>
          </div> 
          <div>
            <div className="relative">
              
            <div className="relative hidden min-[906px]:block w-full">
            <div className="absolute inset-0 bg-primary opacity-50"></div>
            <video  autoPlay loop muted className="w-full object-cover" >
                <source src="/BannerLeftSide.webm" />
              </video>
            </div>
            <div className="relative block min-[906px]:hidden w-full">
            <div className="absolute inset-0 bg-primary opacity-50"></div>
            <video  autoPlay loop muted className="w-full h-[50vh]  object-cover" >
                <source src="/BannerLeftSideMobile.webm" />
              </video>
            </div>
              <div className="absolute z-50 gap-7 flex-col flex top-[50%]  left-[35%] -translate-y-1/2 items-center max-[905px]:w-[80%] max-[415px]:w-[95%] max-[905px]:left-[50%] max-[905px]:-translate-x-1/2
              ">
               <p className="text-[32px] max-[1080px]:text-[30px] max-[768px]:text-[25px] max-[538px]:text-[20px] max-[768px]:font-medium max-[768px]:leading-8 max-[538px]:leading-6 max-[980px]:text-[25px] font-bold text-center text-white min-[768px]:w-[90%] leading-10">
               Next-Gen Peptide Synthesizers Are on the Way! 
               <br/>
               Stay ahead with cutting-edge technology for precision and efficiency.
              </p>
                <Link to={"/upcoming-products"}>
                  <button className="bg-white max-[447px]:text-sm font-semibold  transition-colors text-primary px-4 py-2 text-base">
                        Upcoming Products
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="relative">
          <div className="absolute inset-0 bg-primary opacity-50 z-20"></div>
            <div className="hidden min-[906px]:block w-full">
              <img src={oliguncleotideImage} alt="Home banner" />
            </div>
            <div className="block min-[594px]:hidden w-full">
              <img src={oliguncleotideImageFull} alt="Home Banner" className="h-[50vh] w-full object-contain" />
            </div>
            <div className="hidden min-[594px]:block min-[906px]:hidden  w-full">
              <img src={oliguncleotideImageFull} alt="Home Banner" className="h-[50vh] w-full object-contain" />
            </div>

            <div className="absolute z-50 flex flex-col gap-7 w-[60%] top-[50%]  left-[45%] -translate-y-1/2 min-[905px]:left-[40%] min-[905px]:translate-x-0 max-[905px]:left-[50%] max-[905px]:-translate-x-1/2 max-[905px]:w-[70%] max-[415px]:w-[95%] items-center text-center">

              <p className="text-[32px] max-[1080px]:text-[30px] max-[768px]:text-[25px] max-[538px]:text-[26px] max-[768px]:font-medium max-[768px]:leading-8 max-[538px]:leading-8 max-[980px]:text-[25px] font-bold text-center text-white w-[70%] leading-10 z-50">
                      Next generation oligonucleotide synthesizers coming soon.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    );
  };
