
import { useState } from 'react';
import { Link } from 'react-router-dom';

type ProductDropProps = {
  handleNavbar:  () => void;
}

const ProductDropDown = ({handleNavbar} : ProductDropProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = ()=>{
    setDropdownOpen(!isDropdownOpen);
  }

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <div className="relative inline-block text-left w-full">
      <button
        id="dropdownDefaultButton"
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        className=" font-semibold text-center inline-flex items-center "
        type="button"
      >
        Products
        <svg
          className={`w-2.5 h-2.5 ms-1 ${isDropdownOpen ? 'rotate-180' : ''}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {/* Dropdown menu */}
      {isDropdownOpen && (
         <div
         className={`z-10 min-[920px]:absolute left-0  origin-top-left md:bg-white divide-y w-[100%]  min-[920px]:w-48  ${
           isDropdownOpen ? 'block' : 'hidden'
         }`}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
       >
          <ul className="py-2 min-[920px]:w-full text-sm list-none" aria-labelledby="dropdownDefaultButton">
            <li>
                <Link to={'peptide-synthesizers'} onClick={handleNavbar} className="block px-4 py-2 max-[920px]:hover:bg-gray-100 min-[920px]:hover:bg-primary min-[920px]:hover:text-white">
                    Peptide Synthesizers
                </Link>
            </li>
            <li>
                <Link to={'amino-acids'} onClick={handleNavbar} className="block px-4 py-2 max-[920px]:hover:bg-gray-100 min-[920px]:hover:bg-primary min-[920px]:hover:text-white">
                    Amino Acids
                </Link>
            </li>
            <li>
                <Link to={'reagents-and-linkers'} onClick={handleNavbar} className="block px-4 py-2 max-[920px]:hover:bg-gray-100 min-[920px]:hover:bg-primary min-[920px]:hover:text-white">
                    Reagents & Linkers
                </Link>
            </li>
            <li>
                <Link to={'resins'} onClick={handleNavbar} className="block px-4 py-2 max-[920px]:hover:bg-gray-100 min-[920px]:hover:bg-primary min-[920px]:hover:text-white">
                    Resins
                </Link>
            </li>
            <li className='relative'>
                <Link to={'upcoming-products'} onClick={handleNavbar} className="block px-4 py-2 max-[920px]:hover:bg-gray-100 min-[920px]:hover:bg-primary min-[920px]:hover:text-white">
                    <span className='absolute bg-green-500 top-0 bottom-0 w-[6px] left-[3px]'>

                    </span>
                    Upcoming Products
                </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductDropDown;
