import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useAxios } from "../Hooks/useAxios";
import { Product } from "../Components/Product.types";
import { SpecificationTable } from "../Components/SpecificationTable";
import { LoadingSpinner } from "../Components/LoadingSpinner";

type OtherProductDetailsProps = {
  Category: string;
};

export const OtherProductDetails = ({ Category }: OtherProductDetailsProps) => {
  const { name } = useParams();
  const [product, setProduct] = useState<Product>();
  const { response } = useAxios({
    method: "get",
    url: `/api/product/${name}`,
  });

  useEffect(() => {
    if (response != null) {
      setProduct(response.data.result);
      document.title = product?.name as string;
    }
  }, [response, product?.name]);

  return (
    <main>
      <div className="xl:px-32 lg:px-20 md:px-10 px-5">
        <div className="md:my-5 my-3">
          <h2 className="font-bold text-xl md:text-2xl">{Category}</h2>
          <Breadcrumb />
        </div>
      </div>
      <section className="bg-primary bg-opacity-[0.15] xl:px-32 lg:px-20 md:px-10 px-5  py-10">
        {!product && <LoadingSpinner />}
        {product && (
          // <div className="flex md:flex-row flex-col  place-content-center items-center gap-10 ">
          <div className="grid md:grid-cols-12 grid-cols-1 place-content-center items-center gap-10">
            <figure className="md:col-span-3 mx-auto relative overflow-hidden justify-start">
              {product?.image && (
                <img src={product.image.toString()} alt="" className="w-full" />
              )}
            </figure>

            <article className="md:col-span-9 flex justify-center lg:justify-start">
              <div>
                <h4 className="xl:px-12 lg:px-8 font-bold text-xl md:text-2xl mb-6">
                  {product.name}

                  {product.availability == 1 && (
                    <div className="bg-red-600 mt-2 w-40 text-center px-4 shadow-lg text-white font-medium text-sm">
                      Not available
                    </div>
                  )}
                  {product.availability == 2 && (
                    <div className="bg-green-500 mt-2 w-40 text-center px-4 shadow-lg text-white font-medium text-sm">
                      Coming Soon
                    </div>
                  )}
                </h4>
                <div className="xl:px-12 lg:px-8">
                  <table className="table-fixed">
                    <thead>
                      <tr>
                        <th className="w-56 text-start ps-1">Quantity</th>
                        <th className="w-56 text-start">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product &&
                        product.prices.length > 0 &&
                        product.prices.map((price, index) => (
                          <tr
                            key={index}
                            className="odd:bg-primary odd:bg-opacity-[0.15] font-medium"
                          >
                            <td className="py-1 ps-1">{price.quantity}</td>
                            <td className="py-1">${price.price}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
          </div>
        )}
      </section>

      {product?.specifications && (
        <SpecificationTable specs={product.specifications} />
      )}
    </main>
  );
};
