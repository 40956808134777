import { useCallback, useState } from 'react'
import { ProductType } from '../Components/Products';
import { OtherProductsCard } from '../Components/OtherProductsCard';
import { LoadingSpinner } from '../Components/LoadingSpinner';
import Breadcrumb from '../Components/Breadcrumb';
import { useSetPageTitle } from '../Hooks/useSetPageTitle';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';
import { debounce } from 'lodash';
import { SearchBar } from '../Components/SearchBar';

export const ReagentsProducts = () => {
  useSetPageTitle("Reagents & Linkers");
  const [products, setProducts] = useState<ProductType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>('');


  const fetchData = useCallback(async (pageNumber: number, search: string) => {
    if(loading || loadingMore) return;
    if (pageNumber === 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    try {
      const response = await axios.get(`/api/product?category=3&page=${pageNumber}&limit=15&search=${search}`);
      const responseData = response.data.products;
      if (pageNumber === 1) {
        setProducts(responseData);
      } else {
        setProducts((prevData) => prevData.concat(responseData));
      }
      setHasMore(responseData.length > 0);
      setPage(pageNumber + 1);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (hasMore) {
      setLoadingMore(true); 
      fetchData(page, queryString); 
    }
  };

  const handleSearchChange = debounce(async (search:string)=>{
    setQueryString(search);
    setProducts([]);
    setLoading(true);
    setPage(1)
    fetchData(1,search);
  }, 500)

  const renderData = () => {
    return products?.map((product) => <OtherProductsCard key={product.id} product={product} route="reagents-and-linkers" />);
  };

  
  return (
    <div className='xl:px-32 lg:px-20 md:px-10 px-5 flex flex-col'>
      <div className='flex sm:justify-between sm:items-center mb-5 sm:mb-0 sm:flex-row flex-col' >
        <div className='md:my-5 my-3'>
            <h2 className='font-bold text-xl md:text-2xl'>Reagents & Linkers</h2>  
            <Breadcrumb />
        </div>
        {/* <SearchBar onChange={handleSearchChange} /> */}
      </div>

           {/* {<InfiniteScroll
          key={Math.random()}
          pageStart={1}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={page === 1 ? <div key={0}></div> :<LoadingSpinner key={0} />}
        >
          <div className='grid sm:grid-cols-2  md:grid-cols-3 gap-4 mb-10'>
            {products && renderData()}
          </div>
        </InfiniteScroll>}

        {!loading && products ? (products?.length === 0) && <div className='text-2xl font-bold flex justify-center items-center h-[63vh]'>
                <p className='-mt-32'>
                    No products found
                </p>
            </div> : <LoadingSpinner />} */}

<div className='text-2xl font-bold flex justify-center items-center h-[63vh]'>
          <p className='-mt-32'>
            Products are coming soon. Stay Tuned !!
          </p>
    </div>
      
    </div>
  )
}
