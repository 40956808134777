import { useCallback, useState } from 'react'
import { OtherProductsCard } from '../Components/OtherProductsCard';
import { ProductType } from '../Components/Products';
import { LoadingSpinner } from '../Components/LoadingSpinner';
import Breadcrumb from '../Components/Breadcrumb';
import { useSetPageTitle } from '../Hooks/useSetPageTitle';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';
import { debounce } from 'lodash';
import { SearchBar } from '../Components/SearchBar';

export const ResinsProduct = () => {
    useSetPageTitle("Resins");
    const [products, setProducts] = useState<ProductType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>('');

  
  const fetchData = useCallback(async (pageNumber: number, search: string) => {
    if (loading || loadingMore) return;
    setLoading(true);
    try {
      const response = await axios.get(`/api/product?category=4&page=${pageNumber}&limit=15${search ? `&search=${search}` : ''}`); 
      const responseData = response.data.products;
      if (responseData.length === 0) {
        setHasMore(false);
      } else {
        setProducts((prevData) => prevData?.concat(responseData));
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  },[loading, loadingMore]);

  const handleSearchChange = debounce(async (search:string)=>{
    setQueryString(search);
    setProducts([]);
    setLoading(true);
    setPage(1)
    fetchData(1,search);
  }, 500)


  const loadMore = () => {
    if (hasMore) {
      setLoadingMore(true); 
      fetchData(page,queryString); 
    }
  };

  const renderData = () => {
    return products?.map((product) => <OtherProductsCard key={product.id} product={product} route="Resins" />);
  };

  
  return (
    <div className='xl:px-32 lg:px-20 md:px-10 px-5 flex flex-col'>
      <div className='flex sm:justify-between sm:items-center mb-5 sm:mb-0 sm:flex-row flex-col'>
        <div className='md:my-5 my-3'>
            <h2 className='font-bold text-xl md:text-2xl'>Resins</h2>  
            <Breadcrumb />
        </div>
        {/* <SearchBar onChange={handleSearchChange} /> */}
      </div>
      

           {/* {<InfiniteScroll
          key={Math.random()}
          pageStart={1}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={page === 1 ? <div key={0}></div> :<LoadingSpinner key={0} />}
        >
          <div className='grid sm:grid-cols-2  md:grid-cols-3 gap-4 mb-10'>
            {products && renderData()}
          </div>
        </InfiniteScroll>}
        {!loading && products ? (products?.length === 0) && <div className='text-2xl font-bold flex justify-center items-center h-[63vh]'>
                <p className='-mt-32'>
                    No products found
                </p>
            </div> : <LoadingSpinner />} */}
             <div className='text-2xl font-bold flex justify-center items-center h-[63vh]'>
          <p className='-mt-32'>
            Products are coming soon. Stay Tuned !!
          </p>
    </div>
    </div>
  )
}
