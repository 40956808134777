import { Link, useParams } from 'react-router-dom'
import ProductImg from '../assets/product-img.webp'
import { CustomButton } from '../Components/CustomButton';
import Breadcrumb from '../Components/Breadcrumb';
import { SpecificationTable } from '../Components/SpecificationTable';
import { useAxios } from '../Hooks/useAxios';
import { useEffect, useState } from 'react';
import { Product } from '../Components/Product.types';
import { Parts } from '../Components/Parts';
import { LoadingSpinner } from '../Components/LoadingSpinner';

export const ProductDetails = () => {
    const {id} = useParams();
    const [product ,setProduct] = useState<Product>();
    const {response} = useAxios({
        method: 'get',
        url: `/api/product/${id}`
    })

    useEffect(()=>{
      if(response != null){
        setProduct(response.data.result)
        document.title = product?.name as string;
      }
    },[response, product?.name])
    useEffect(()=>{
        document.title = `${product?.name} - Peptide Machines` as string;
    },[product])




    return product && ProductImg ? (
        <main className=''>
        <div className='xl:px-32 lg:px-20 md:px-10 px-5'>
        <div className='md:my-5 my-3'>
            <h2 className='font-bold text-xl md:text-2xl'>Peptide Synthesizers</h2>  
            <Breadcrumb />
        </div>
        </div>
        <section className='bg-primary bg-opacity-[0.15] xl:px-32 lg:px-20 md:px-10 px-5  py-10'>
           
            <div className='grid md:grid-cols-12 grid-cols-1 place-content-center items-center gap-10 relative'>
                <figure className='md:col-span-4 self-center flex justify-center items-center'>
                    {product.image && <img src={product.image.toString()} alt='' className='md:w-[90%] w-[70%] md:mx-0 mx-auto'/>}
                </figure>
                {/* <div className='col-span-1'></div> */}
                <article className='md:col-span-8'>
                    <div className='flex flex-col gap-2 justify-center items-start '>
                        <h4 className='xl:px-12 lg:px-8 font-bold text-xl md:text-2xl mb-6'>{product.name}
                        {product.availability == 0 && 
                        <div className=" bg-red-600 w-36 text-center
                          text-white font-medium px-5 text-sm">
                            Not available
                        </div>}
                        {product.availability == 2 && 
                        <div className=" bg-green-500 w-36 text-center
                          text-white font-medium px-5 text-sm">
                           Coming Soon
                        </div>}
                        </h4>
                        
                    </div>
               
                    <p className='xl:px-12 lg:px-8 sm:leading-7 leading-6 md:text-base xl:text-lg font-medium'>{product.shortDescription}</p>
                </article>
                
            </div>
        </section>
        <section className='xl:px-32 lg:px-20 md:px-10 sm:px-5 px-3 flex-wrap ms-2 sm:ms-0 flex gap-3  sm:gap-5 my-7'>
            <Link to={'/peptide-synthesizers'}>
                <CustomButton title="Related Products" className='text-sm md:text-base ' />
            </Link>
            <Link to={'/request-quote'}>
                <CustomButton title="Request a Quote" className='text-sm md:text-base' />
            </Link>
            {product.document && <a className='bg-primary  cursor-pointer transition-colors hover:bg-[#04565A] text-white px-4 py-2 text-base font-medium' href={product.document.toString()} download={true}> Download Brouchure</a>}
            {/* <a className='bg-primary  cursor-pointer transition-colors hover:bg-[#04565A] text-white px-4 py-2 text-sm sm:text-base font-medium'  download={true}> Download Brouchure</a> */}
            
        </section>
        <article className='xl:px-36 lg:px-20 md:px-10 px-10 '>
            <ul className='list-[square] text-base md:text-lg'>
                {product.keyPoints.map((keyPoint, index)=>{
                    return (
                        <li className='mb-3' key={index}>
                        <p>{keyPoint}</p>
                        </li>
                    )
                })}
            </ul>
        </article>

        {product.specifications.length > 0 && <SpecificationTable specs = {product.specifications} />}
        {product.parts?.length !== 0 && <Parts parts= {product.parts}/>}
        </main>
    ) : (
        <LoadingSpinner />
    );
    

}
