import { Card } from "./Card";
import { Link } from "react-router-dom";

export const AminoAcids = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3  gap-10 md:gap-5 lg:gap-3 xl:gap-0  xl:px-32 lg:px-20 md:px-10 px-5  md:mt-7 mb-16 justify-items-center items-center text-[#4b4b4b]">
      <Link to={'amino-acids'}>
        <Card className="group  hover:shadow-none border-2 hover:border-primary relative overflow-hidden">
        <div className="bg-green-500 text-white px-7 -rotate-45 absolute top-8 md:top-[1.9rem] lg:top-9 md:-left-[2rem] -left-9 lg:-left-9 shadow-lg md:text-[0.8rem] lg:text-base xl:text-lg">
          Coming soon
        </div>

          <div className="flex flex-col justify-center items-center p-12 md:p-4 lg:px-7 lg:py-8 xl:px-12 xl:py-12 gap-3">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="36%"
              height="36%"
              viewBox="0 0 315.000000 315.000000"
              preserveAspectRatio="xMidYMid meet"
              className="mb-3"
            >
              <g
                transform="translate(0.000000,315.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
                className="group-hover:fill-primary fill-[#4b4b4b]"
              >
                <path
                  d="M1142 3130 c-7 -11 -19 -20 -27 -20 -17 0 -61 -46 -81 -86 -19 -35
          -18 -103 2 -142 8 -18 35 -48 60 -68 l44 -36 0 -372 c0 -289 -3 -376 -13 -396
          -8 -14 -38 -68 -67 -120 -29 -52 -64 -115 -78 -140 -13 -25 -39 -70 -58 -101
          -19 -31 -34 -59 -34 -63 0 -3 -18 -36 -41 -72 -22 -37 -45 -77 -50 -88 -5 -12
          -23 -43 -40 -71 -16 -27 -35 -61 -42 -75 -6 -14 -15 -27 -19 -30 -4 -3 -11
          -17 -17 -30 -6 -14 -27 -52 -46 -85 -19 -33 -38 -67 -42 -75 -4 -8 -26 -48
          -50 -88 -24 -40 -43 -75 -43 -77 0 -3 -11 -22 -23 -43 -22 -34 -48 -80 -129
          -229 -15 -29 -28 -61 -28 -72 0 -11 -4 -22 -9 -26 -5 -3 -13 -34 -16 -69 -13
          -119 50 -266 151 -355 50 -44 119 -81 151 -81 11 0 24 -5 29 -10 16 -16 1856
          -8 1912 8 50 14 73 26 127 67 47 35 56 45 99 110 98 148 91 319 -19 495 -12
          19 -39 67 -60 105 -43 78 -56 102 -89 160 -12 22 -40 72 -61 110 -37 68 -67
          120 -92 160 -7 11 -19 34 -27 50 -8 17 -29 53 -45 80 -17 28 -35 59 -40 71 -5
          11 -28 51 -50 88 -23 36 -41 69 -41 72 0 4 -20 40 -45 81 -25 40 -45 76 -45
          80 0 3 -15 31 -34 62 -30 49 -64 110 -113 201 -10 20 -13 106 -13 396 l0 372
          45 37 c71 58 92 131 59 210 -19 46 -41 71 -77 84 -15 6 -27 17 -27 26 0 13
          -50 15 -418 15 -406 0 -418 -1 -430 -20z m840 -127 c24 -21 23 -68 -2 -93 -17
          -17 -33 -20 -98 -20 -91 0 -122 -15 -122 -60 0 -35 11 -43 70 -50 l45 -5 5
          -398 c3 -253 9 -400 15 -405 6 -4 16 -18 22 -32 7 -14 26 -47 42 -75 17 -27
          35 -60 40 -72 6 -12 21 -39 34 -60 13 -21 40 -67 59 -103 85 -156 99 -182 123
          -220 42 -67 39 -70 -77 -71 -96 0 -132 -4 -248 -27 -66 -13 -190 -58 -294
          -106 -205 -96 -284 -115 -491 -115 -135 0 -194 7 -280 31 -60 18 -60 24 -4
          120 21 36 39 69 39 72 0 3 20 39 45 79 25 41 45 77 45 80 0 4 20 40 45 80 25
          41 45 77 45 80 0 4 20 40 45 80 25 41 45 77 45 81 0 4 11 24 25 46 14 22 40
          66 58 97 l32 58 5 390 5 390 48 6 c50 5 67 19 67 56 0 37 -36 53 -120 53 -91
          0 -120 15 -120 62 0 17 7 40 17 50 15 17 41 18 416 18 359 0 402 -2 419 -17z
          m353 -1809 c14 -16 24 -33 25 -38 0 -5 20 -42 45 -83 25 -40 45 -76 45 -80 0
          -3 20 -39 45 -80 25 -40 45 -76 45 -80 0 -3 15 -31 34 -62 19 -31 49 -83 66
          -116 85 -156 80 -142 80 -236 0 -48 -4 -90 -9 -94 -5 -3 -12 -19 -16 -36 -9
          -41 -100 -131 -159 -158 -49 -21 -49 -21 -972 -21 l-924 0 -57 31 c-119 64
          -173 151 -173 281 0 68 4 88 31 141 16 34 34 66 38 72 8 9 32 53 109 195 13
          25 40 72 59 105 19 33 38 66 42 74 14 27 38 36 63 23 22 -12 66 -22 178 -43
          69 -13 361 -5 415 11 22 6 72 21 110 32 39 12 108 39 155 60 47 22 108 49 135
          59 28 11 59 24 70 28 78 35 192 50 358 47 l138 -2 24 -30z"
                />
                <path
                  d="M1542 2064 c-32 -22 -31 -81 2 -90 14 -3 34 -3 46 1 17 5 21 13 18
          42 -4 48 -34 69 -66 47z"
                />
                <path
                  d="M1245 1691 c-52 -21 -105 -103 -105 -161 0 -36 32 -110 56 -132 27
          -23 91 -48 124 -48 29 0 85 21 110 42 45 37 60 71 60 130 0 111 -66 179 -172
          177 -29 0 -62 -4 -73 -8z m99 -103 c61 -27 43 -128 -22 -128 -31 0 -72 36 -72
          62 0 37 23 64 67 77 1 1 13 -4 27 -11z"
                />
                <path
                  d="M1771 1686 c-22 -26 -9 -69 23 -81 25 -8 31 -6 53 19 21 25 23 30 10
          50 -17 26 -69 33 -86 12z"
                />
                <path
                  d="M792 462 c-7 -5 -14 -21 -15 -37 -1 -22 4 -32 25 -44 54 -31 106 31
          60 72 -20 18 -49 22 -70 9z"
                />
                <path
                  d="M1038 459 c-26 -15 -22 -54 8 -78 26 -21 35 -21 641 -21 611 0 615 0
          645 21 24 18 28 26 23 48 -4 14 -12 29 -18 33 -19 12 -1279 9 -1299 -3z"
                />
              </g>
            </svg>
            <span className=" group-hover:text-primary font-bold md:text-base lg:text-xl">
              Amino Acids
            </span>
          </div>
        </Card> 
      </Link>

      <Link to={'reagents-and-linkers'}>
        <Card className="group  hover:shadow-none border-2 hover:border-primary relative overflow-hidden">
        <div className="bg-green-500 text-white px-7 -rotate-45 absolute top-8 md:top-[1.9rem] lg:top-9 md:-left-[2rem] -left-9 lg:-left-9 shadow-lg md:text-[0.8rem] lg:text-base xl:text-lg">
          Coming soon
        </div>
          <div className="flex flex-col justify-center items-center p-12 md:p-4 lg:p-7  xl:p-11 gap-3">

          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="43%"
            height="43%"
            x="0"
            y="0"
            viewBox="0 0 511.801 511.801"
            xmlSpace="preserve"
            className=""
            >
            <g className="group-hover:fill-primary fill-[#4b4b4b]">
              <circle
                cx="79.459"
                cy="170.769"
                r="19.062"
                strokeWidth="15"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                fill="none"
                stroke="#000000"
                className="group-hover:stroke-primary stroke-[#4b4b4b]"
                ></circle>
              <path
                d="M7.5 151.707v38.124M33.882 151.707v38.124M7.5 170.116h26.382"
                strokeWidth="15"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                fill="none"
                stroke="#000000"
                className="group-hover:stroke-primary stroke-[#4b4b4b]"
                ></path>
              <circle
                cx="79.459"
                cy="397.946"
                r="19.062"
                strokeWidth="15"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                fill="none"
                stroke="#000000"
                className="group-hover:stroke-primary stroke-[#4b4b4b]"
                ></circle>
              <path
                d="M7.5 378.884v38.124M33.882 378.884v38.124M7.5 397.293h26.382M103.86 245.579 65.091 214.41M103.86 320.791 65.091 351.96M454.71 243.412l-53.582-43.078-53.581 43.078-53.587-43.078-53.581 43.078M293.653 200.334v-35.973"
                strokeWidth="15"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                fill="none"
                stroke="#000000"
                className="group-hover:stroke-primary stroke-[#4b4b4b]"
                ></path>
              <circle
                cx="267.931"
                cy="113.855"
                r="19.062"
                strokeWidth="15"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                fill="none"
                stroke="#000000"
                className="group-hover:stroke-primary stroke-[#4b4b4b]"
                ></circle>
              <path
                d="M312.054 94.793v38.124M338.436 94.793v38.124M312.054 113.201h26.382M425.961 179.043v-37.129l25.103 36.35c.465.672.964.343.964-.475v-35.945M478.488 141.845v37.198M504.23 141.845v37.198M478.488 159.806h25.742M436.352 272.755v39.135M463.434 272.755v39.135M436.352 291.652h27.082M408.758 276.097a19.476 19.476 0 0 0-10.94-3.342c-10.807 0-19.568 8.761-19.568 19.567 0 10.807 8.761 19.567 19.568 19.567 4.389 0 7.999-1.445 10.739-3.885a15.57 15.57 0 0 0 1.588-1.639M485.848 299.57a9.927 9.927 0 0 1 8.522-4.83c5.485 0 9.931 4.446 9.931 9.931s-4.446 9.931-9.931 9.931M494.371 314.602c5.485 0 9.931 4.446 9.931 9.931s-4.446 9.931-9.931 9.931a9.925 9.925 0 0 1-8.482-4.763M243.018 306.907v10.73c0 3.704-1.976 7.126-5.183 8.978l-59.674 34.453a10.365 10.365 0 0 1-10.366 0l-59.674-34.453a10.367 10.367 0 0 1-5.183-8.978v-68.905c0-3.704 1.976-7.126 5.183-8.978l11.98-6.917M150.374 215.36l17.421-10.058a10.365 10.365 0 0 1 10.366 0l59.674 34.453a10.367 10.367 0 0 1 5.183 8.978v23.32"
                strokeWidth="15"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                fill="none"
                stroke="#000000"
                className="group-hover:stroke-primary stroke-[#4b4b4b]"
                ></path>
            </g>
          </svg>

          <span className="group-hover:text-primary font-bold text-lg md:text-base  lg:text-xl ">
            Reagent & Linkers
          </span>
            </div>
        </Card>
      </Link>
      <Link to={'resins'} >
        <Card className="group  hover:shadow-none border-2 hover:border-primary relative overflow-hidden">
        <div className="bg-green-500 text-white px-7 -rotate-45 absolute top-8 md:top-[1.9rem] lg:top-9 md:-left-[2rem] -left-9 lg:-left-9 shadow-lg md:text-[0.8rem] lg:text-base xl:text-lg">
          Coming soon
        </div>
          <div className="flex flex-col justify-center items-center p-12 md:p-4 lg:p-7 xl:p-12 gap-3">

          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="43%"
            height="43%"
            x="0"
            y="0"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            className="group"
            >
            <g className="group-hover:fill-primary fill-[#4b4b4b] ">
              <path
                d="M366.9 415.9a8 8 0 0 0 8-8v-49.712a8 8 0 0 0-4-6.929L327.851 326.4a8 8 0 0 0-8 13.857l39.049 22.549V407.9a8 8 0 0 0 8 8zM185.746 80.21l63.053 36.4v47.84a8 8 0 0 0 16 0v-47.837l63.052-36.4a8 8 0 0 0-8-13.856l-63.052 36.4-63.053-36.4a8 8 0 0 0-8 13.856zM291.851 291.759a8 8 0 0 0 16 0v-49.712a8 8 0 0 0-4-6.928L260.8 210.263a8 8 0 0 0-8 13.856l39.052 22.547z"
                fill="#000000"
                opacity="1"
                data-original="#000000"
                className="group-hover:fill-primary fill-[#4b4b4b]"
                ></path>
              <path
                d="m453.954 298.687-63.055 36.4L331.851 301v-72.81a8 8 0 0 0-4-6.928L260.8 182.55a8 8 0 0 0-8 0l-67.052 38.712a8 8 0 0 0-4 6.928V301l-59.053 34.094-63.051-36.4a8 8 0 0 0-8 13.857l63.051 36.4v72.806a8 8 0 1 0 16 0V348.95l59.052-34.094L248.8 348.95v11.956a8 8 0 0 0 16 0V348.95l59.052-34.094L382.9 348.95v68.187l-59.049 34.093-59.051-34.093v-31.485a8 8 0 0 0-16 0v36.1a8 8 0 0 0 4 6.928l67.051 38.72a8 8 0 0 0 8 0l67.049-38.716a8 8 0 0 0 4-6.928v-72.81l63.052-36.4a8 8 0 0 0-8-13.856zm-256.207-65.878 59.053-34.093 59.052 34.093V301L256.8 335.09 197.747 301zM358.869 75.394a23.863 23.863 0 1 0-23.863-23.862 23.89 23.89 0 0 0 23.863 23.862zm0-31.725a7.863 7.863 0 1 1-7.863 7.863 7.871 7.871 0 0 1 7.863-7.863zM158.537 75.394a23.863 23.863 0 1 0-23.863-23.862 23.89 23.89 0 0 0 23.863 23.862zm0-31.725a7.863 7.863 0 1 1-7.863 7.863 7.871 7.871 0 0 1 7.863-7.863zM23.862 263.12a23.863 23.863 0 1 0 23.863 23.863 23.89 23.89 0 0 0-23.863-23.863zm0 31.725a7.863 7.863 0 1 1 7.863-7.862 7.871 7.871 0 0 1-7.863 7.862zM488.138 263.12A23.863 23.863 0 1 0 512 286.983a23.89 23.89 0 0 0-23.862-23.863zm0 31.725a7.863 7.863 0 1 1 7.862-7.862 7.872 7.872 0 0 1-7.862 7.862zM119.973 436.606a23.863 23.863 0 1 0 23.862 23.862 23.89 23.89 0 0 0-23.862-23.862zm0 31.725a7.863 7.863 0 1 1 7.862-7.863 7.872 7.872 0 0 1-7.862 7.863z"
                fill="#000000"
                opacity="1"
                data-original="#000000"
                className="group-hover:fill-primary fill-[#4b4b4b]"
                ></path>
            </g>
          </svg>

          <span className=" group-hover:text-primary font-bold md:text-base  lg:text-xl">
            Resins
          </span>
                </div>
        </Card>
      </Link>
    </div>
  );
};
